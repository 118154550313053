import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { getProjects, patchProject } from "services/project";
import { getFullProjectLayer } from "services/layer";
import {
  getScenarios,
  getScenariosRaw,
  patchScenario
} from "services/scenario";
import { geoprocessGetAvailable } from "services/geoprocess";
import { patchCategoryConfig } from "services/category";
import { getAllCapabilities } from "services/capabilities";
import Snackbar from "@material-ui/core/Snackbar";
import { getPylons } from "components/Dashboard3D/CesiumPath";

import {
  SET_WEIGHT_ABS,
  SET_WEIGHT_REL,
  SET_WEIGHT_NOWEIGHT,
  F11,
  T_VIEWER,
  EDITOR_MODES
} from "assets/global";
import { getBaseMaps } from "services/background";
import axios from "axios";
import { ContextLimits, Ion } from "cesium/Build/Cesium/Cesium";
import {
  findLayerIndexById,
  findCategoryIndexById,
  countLayersInCategory
} from "utils/LayerTools";
import { parseCatchErr } from "utils/handlers";
import { getSortedData } from "utils/DataTools";
import { withTranslation } from "react-i18next";

import { searchObjectInArray } from "utils/ArrayTools";
import { CHANGE_PROJECT_STATE } from "assets/global";
import { waitForStateToUpdate } from "utils/CoreUtils";

// Third-party
import * as Sentry from "@sentry/react";

const AppContext = React.createContext();
export { AppContext };

const clone = require("rfdc")();

class AppProvider extends Component {
  constructor() {
    super();
    this.state = {
      // Using the appContext to store the data for the tasks is a temporary solution until we stop using classes in the AppContext and make sure every file using the AppContext is not a class component
      tasks: {},
      tasksLogOpen: true,
      ///////////////////////////////
      isFullscreen: document[this.getBrowserFullscreenElementProp()] != null,
      projectsLoaded: false,
      projects: [], // Array with the projects of the user
      project: [], // Active project
      setProject: this.setProject, //change active project
      scenarios_raw: [], // Array with scenarios returned in the project object
      scenarios: [], // Array with user scenarios with detailed information
      active_scenario: 0,
      geoprocess: false,
      editMode: EDITOR_MODES.NO_EDITOR, // Boolean edit mode flag
      wizard_area: null, // User had selected area for the new project flag
      wizard: false, // Is wizard modal active flag
      message: "Hi user!", // Default Snackbar message
      left_tab: 1, // variable that sets the active tab in the left menu
      right_tab: 0, // right bar open tab
      rightOpen: true,
      left0pen: true,
      category_total_weight: 10, // Default value for total weight text
      category_setting: SET_WEIGHT_ABS, // category weight option
      //Variables from Dashboard3D
      userSettings: {},
      backgrounds: [], //this will contain structure of WMS (if false, means it never loaded)
      dataSources: [],
      loadedLayer: [],
      layers: [],
      projects_open: false,
      intermediate_points: [],
      userProfile: false,
      userProfileActiveTab: "profile",
      optimalPathCost: "", // TEMPORAL FIX (while multiple scenario paths are implemented)
      userCapabilities: { raster_ui_limit: 2 },
      modelFiles: "url", // [ url , api ]//you can change this var to get the models files from API as arraybuffer or use direct URL
      models: null,
      selectedModel: null,
      map2DReady: false,
      map3DReady: false,
      scenariosLoaded: false,
      projectLoadState: CHANGE_PROJECT_STATE.IDLE,
      projectLoadingID: undefined,
      isScenarioPointsEditorOpen: false // TODO: In the future, move this to the UIContext. I had to do it here right now to support the `MapProvider` class component behavior but once that is moved to a functional component, we can move this to the UIContext
    };
    this.timeout = null;
  }

  /**
   * Set pylons from AppContext
   */
  setMapPylons = () => {
    getPylons(this.state.modelFiles)
      .then(res => {
        this.setState({ models: res, selectedModel: res[0] }, () => {});
      })
      .catch(err => console.log(err));
  };

  /**
   * Get model by id
   * @param {number} id - Model id, if it is 0 or empty, it will return the first model
   * @returns {object|null} It returns the model object or null if it doesn't exist
   */
  getModel = id => {
    const models = this.state?.models;

    // Related to this: https://gitlab.com/stefano.g/pathfinder_dev/-/issues/3251
    // The issue could happen if the models are loaded but there are no models
    // in the array, or if the models failed loading for some reason
    // In those cases, we should return null
    // I have tried a little and retuning null doesn't seem to break anything
    // but if we use this function anywhere, we must be sure that we are handling the null return
    if (!models || models.length === 0) {
      Sentry.captureException(new Error("Models are not loaded or empty"));
      return null;
    }

    // if no model is selected
    if (!id) {
      return models[0];
      // get model data from models array
    } else {
      for (let index = 0; index < models.length; index++) {
        if (models[index].id === id) {
          return models[index];
        }
      }
    }

    // There is an implicit return null here, but I like to be explicit
    // so adding it for the patch
    Sentry.captureException(new Error(`Model not found with the id ${id}`));
    return null;
  };

  /**
   * Set selected model
   * @param {*} model
   */
  selectedModel = model => {
    this.setState({ selectedModel: model });
  };

  // Set fullscreen status
  setFullscreen = () => {
    if (!document.documentElement) return;

    document.documentElement
      .requestFullscreen()
      .then(() => {
        this.setState({
          isFullscreen: document[this.getBrowserFullscreenElementProp()] != null
        });
      })
      .catch(() => {
        this.setState({ isFullscreen: false });
      });
  };

  /**
   * Exit to fullscreen mode
   * @returns
   */
  handleExitFullscreen = () => {
    // First check if it is in fullscreen mode
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/fullscreenElement
    if (!document.fullscreenElement) return;

    document.exitFullscreen();
  };
  /**
   * Function that sets fullscreen to true
   */
  toggleFullscreen = () => {
    this.state.isFullscreen
      ? this.handleExitFullscreen()
      : this.setFullscreen();
  };

  /**
   * Get browser fullscreen element property
   * @returns
   */
  getBrowserFullscreenElementProp() {
    if (typeof document.fullscreenElement !== "undefined") {
      return "fullscreenElement";
    } else if (typeof document.mozFullScreenElement !== "undefined") {
      return "mozFullScreenElement";
    } else if (typeof document.msFullscreenElement !== "undefined") {
      return "msFullscreenElement";
    } else if (typeof document.webkitFullscreenElement !== "undefined") {
      return "webkitFullscreenElement";
    } else {
      throw new Error("fullscreenElement is not supported by this browser");
    }
  }

  // returns the raster ui limit value as text
  getMaxLayersString = () => {
    let value = 0;
    value = this.state.userCapabilities["raster_ui_limit"] || value;
    return String(value);
  };

  // returns the number of visible raster layers in the active scenario
  getActiveRasterLayers = () => {
    const scenario = this.state.scenarios[this.state.active_scenario];
    let numActiveLayers = 0;
    if (scenario) {
      scenario.full_project.categories.forEach(category => {
        category.layers.forEach(layer => {
          if (layer.ltype === "RST") {
            if (layer.config.ui_settings.baseVisible) {
              numActiveLayers++;
            }
          }
        });
      });
    }
    return numActiveLayers;
  };

  appendNewCategory = category => {
    // Extract config list and the rest of the properties as body
    let categoryCopy = clone(category);
    const { config_lst, ...categoryBody } = categoryCopy;

    let scenariosSnapshot = [...this.state.scenarios];

    // Iterate scenarios
    for (let index = 0; index < scenariosSnapshot.length; index++) {
      // Extract config for the scenario
      const config = config_lst.find(
        element => element.scenario === scenariosSnapshot[index].id
      );
      // Build the new config with the body info + config object
      const newCategory = clone({ config: config, ...categoryBody });

      // Push the new category to the category list of the scenario
      scenariosSnapshot[index]["full_project"]["categories"].push(newCategory);
    }

    // Update scenarios with the new scenarios array with the category added
    // Alphabetical order
    getSortedData(scenariosSnapshot, "name");

    this.setState({
      scenarios: scenariosSnapshot
    });
  };

  /*
  Function that receives a layer object and a category_name and returns the
  layer information in certain format
   */
  layer_serializer = (layer, category_name) => {
    let output = {
      category_name: category_name,
      coverage: 0,
      id: layer.config.layer,
      layer: layer.name,
      layer_color: layer.config.layer_color,
      resistance_value: layer.config.resistance_value,
      scenario: layer.config.scenario,
      weight: layer.config.weight
    };
    return output;
  };
  /*
   Function that returns the scenario data from the state.scenarios variable
   */
  scenarioAnalytics = (id = -1) => {
    let data = {
      category_analytics: [],
      layer_analytics: [],
      path_analytics: []
    };
    let categories = [];
    let cat_analytics = {};
    let layers = [];
    try {
      let index = this.state.scenarios.findIndex(item => item.id === id);

      if (index > -1) {
        categories = this.state.scenarios[index].full_project.categories;
      } else {
        categories =
          this.state.scenarios[this.state.active_scenario].full_project
            .categories;
      }

      categories.forEach(category => {
        cat_analytics[category.name] = category.layers.map(layer =>
          this.layer_serializer(layer, category.name)
        );
        category.layers.forEach(layer =>
          layers.push(this.layer_serializer(layer, category.name))
        );
      });
      data["category_analytics"] = cat_analytics;
      data["layer_analytics"] = layers;
    } catch (e) {
      this.props.enqueueSnackbar(this.props.t("error.No_analytics_available"), {
        variant: "error"
      });
    } finally {
      return data;
    }
  };
  //  Function that adds the class navigation_open to the Cesium div container if the
  // right menu is open
  moveCesiumControls = state => {
    try {
      if (state) {
        document
          .getElementsByClassName("cesium-widget-cesiumNavigationContainer")[0]
          .classList.add("navigation_open");
      } else {
        document
          .getElementsByClassName("cesium-widget-cesiumNavigationContainer")[0]
          .classList.remove("navigation_open");
      }
    } catch {
      // There are no elements with class `cesium-widget-cesiumNavigationContainer` it happens in 2D
    }
  };

  /**
   * Check if screen has great WEBGL Cesium context limits
   */
  isContextLimits = () => {
    if (ContextLimits.maximumTextureSize <= 4096) {
      this.props.enqueueSnackbar(this.props.t("error.isContextLimits"), {
        variant: "error"
      });
      return true;
    }
    return false;
  };

  getUserCapabilities = () => {
    return getAllCapabilities().then(res => {
      this.setState({ userCapabilities: res });
    });
  };

  componentWillUnmount() {
    document.onfullscreenchange = undefined;
  }

  componentDidMount() {
    const isAuth =
      localStorage.getItem("appToken") ?? sessionStorage.getItem("appToken");
    if (isAuth) {
      // Load available models
      this.setMapPylons();
      // Populate Lit Projects
      this.loadProjects();
      /*To be deleted when a capabilities files is added*/
      geoprocessGetAvailable()
        .then(res => {
          this.setState({ geoprocess: res.length > 0 });
        })
        .catch(err =>
          this.props.enqueueSnackbar(parseCatchErr(err, this.props.t), {
            variant: "error"
          })
        );
      // to be delete END.

      this.getUserSettings();
      this.getUserCapabilities();
      window.reloadScenario = this.updateScenarios;
      window.testFunction = this.showAllCategoryLayers;

      // Fullscreen change event
      document.onfullscreenchange = () => {
        this.setState({
          isFullscreen: document[this.getBrowserFullscreenElementProp()] != null
        });
      };
      //  Prevent the default event of the F11 key
      window.addEventListener("keydown", e => {
        e = e || window.event;
        if (e.key === F11) {
          e.preventDefault();
          this.toggleFullscreen();
        }
      });

      /*
      DEBUG uncomment line below to load the project as soon as the application
      loads
    */
      // this.loadProject(2);
      // window.growl = this.growl;
    }
  }

  wizardArea = area => {
    // store the area collected in the wizard process in state
    this.setState({ wizard_area: area });
  };
  changeRightTab = tab => {
    // Change the active tab in the right panel
    // @param tab is the tab number
    this.setState({ right_tab: tab });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  /**
   * @param {EDITOR_MODES} state It must be one value of the EDITOR_MODES object
   */
  setEdit = state => {
    this.setState({ editMode: state });
  };

  setMenuOpen = (side, state) => {
    if (side === "left") {
      this.setState({ left0pen: state });
    }
    if (side === "right") {
      this.moveCesiumControls(state);
      this.setState({ rightOpen: state });
    }
    if (side === "both") {
      this.moveCesiumControls(state);
      this.setState({ left0pen: state, rightOpen: state });
    }
  };
  setWizard = state => {
    this.setState({ wizard: state });
  };
  setProjects = state => {
    this.setState({ projects_open: state });
  };
  setProject = project => {
    this.setState({ project: project });
  };
  handleClick = state => () => {
    this.setState({ open: true, ...state });
  };
  handleTab = (event, newValue) => {
    this.setState({ left_tab: newValue });
  };
  handleSnackClose = (event, reason) => {
    // Handles the snackbar message

    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };

  updateProject = (name, value, reload = true) => {
    /*
    API call to update project property values .

    @ param name - scenario property name
    @ param scenario property new value

    IMPORTANT FOR FUTURE DEVELOPEMENT:
    To update the thumbnail a new method should be created, thumbnail object must
    be a file and creates conflict o/therwise, this is the reason why there is
    a condition at sercives/project/patchProject that removes thumbnail from
    the body if it is not a file object.
    It should be managed over the thumbnail endpoints
    */
    let temp = Object.assign({}, this.state.project);
    temp[name] = value;
    return patchProject(this.state.project.id, temp)
      .then(res => {
        this.setState({ project: res });
        // To be deleted: commented since it seems that it is not necessary to
        // launch events that depend on scenarios_raw
        // this.setState({ scenarios_raw: res.scenarios });
        return true;
      })
      .catch(err => {
        this.props.enqueueSnackbar(err, { variant: "error" });
        if (!err.toString().includes("Only the admin")) {
          this.setState({ project: temp });
        }
        return false;
      });
  };
  hasCategoryId = (element, id) => {
    return element.id === id;
  };
  updateLayer = (param, value, scenario_id, category_id, layer_id) => {
    /*
    Internal call to update layer values in the UI without call back end

    */
    try {
      let sceTemp = this.state.scenarios;
      sceTemp[scenario_id].full_project.categories[category_id].layers[
        layer_id
      ].config[param] = value;
      this.setState({ scenarios: sceTemp });
    } catch (err) {
      console.log(err);
    }
  };
  // Method that updates the ui_settings.visible property of a given layer
  updateLayerVisibility = (visible, scenario_id, category_id, layer_id) => {
    try {
      let sceTemp = this.state.scenarios;
      sceTemp[scenario_id].full_project.categories[category_id].layers[
        layer_id
      ].config.ui_settings["visible"] = visible;
      this.setState({ scenarios: sceTemp });
    } catch (err) {
      console.log(err);
    }
  };

  // Method that updates the ui_settings.baseVisible property of a given layer

  updateLayerBaseVisibility = (
    visible,
    scenarioIndex,
    categoryIndex,
    layerIndex
  ) => {
    /*
    Internal call to update layer values in the UI without call back end

    */
    let sceTemp = this.state.scenarios;
    let layer =
      sceTemp[scenarioIndex].full_project.categories[categoryIndex].layers[
        layerIndex
      ];
    // Test if number of active raster layers is greater thanlimit
    if (
      layer.ltype === "RST" &&
      this.getActiveRasterLayers() >=
        this.state.userCapabilities["raster_ui_limit"] &&
      visible
    ) {
      this.props.enqueueSnackbar(
        this.props.t("RasterLimit.Layer") + ": " + this.getMaxLayersString(),
        {
          variant: "warning"
        }
      );
      return null;
    }
    sceTemp[scenarioIndex].full_project.categories[categoryIndex].layers[
      layerIndex
    ].config.ui_settings["baseVisible"] = visible;
    this.setState({ scenarios: sceTemp });
  };
  updateLayerConfig = (config, scenario_x, category_y, layer_z) => {
    /*
    Internal call to update layer values in the UI without call back end

    */
    try {
      let sceTemp = this.state.scenarios;
      sceTemp[scenario_x].full_project.categories[category_y].layers[layer_z][
        "config"
      ] = config;
      // Alphabetical order
      getSortedData(sceTemp, "name");

      this.setState({
        scenarios: sceTemp
      });
    } catch (err) {
      console.log(err);
    }
  };
  updateLayerAtt = (param, value, category_id, layer_id) => {
    /*
    Internal call to update layer values in the UI without call back end, it changes all the name appearences

    */
    try {
      let sceTemp = this.state.scenarios.map(scenario => {
        scenario.full_project.categories[category_id].layers[layer_id][param] =
          value;
        return scenario;
      });
      this.setState({
        scenarios: sceTemp
      });
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Method to update categories with updated layers after rebuffering
   * or changing category.
   *
   * @param {object} originalLayer
   * @param {object} resultLayer
   */
  bufferLayerUpdate = (originalLayer, resultLayer) => {
    // Store current scenarios array.
    let updatedScenarios = clone(this.state.scenarios);

    // Set the layer to dirty
    resultLayer.dirty = true;

    // Store original and destination category ids.
    let originalCategoryId = originalLayer.category;
    let destinationCategoryId = resultLayer.category;

    // We find the scenario index where the updae layer belongs
    const targetScenarioIndex = updatedScenarios.findIndex(
      item => item.id === resultLayer.config.scenario
    );

    // Iterate scenarios to update layer and categories.
    for (let index = 0; index < updatedScenarios.length; index++) {
      // Evaluate if there's been a category change.
      if (originalCategoryId !== destinationCategoryId) {
        // Case with category change.
        let updatedResultLayer = clone(resultLayer);

        // Remove updated layer config property, as we need
        // the original one from the category.
        delete updatedResultLayer.config;

        // Store layer index in the original category.
        let layerIndexInOriginalCategory = findLayerIndexById(
          updatedScenarios[index],
          originalLayer.id,
          originalCategoryId
        );

        // Store layer index in the destination category as
        // the last position in the list of layers
        // (so # of layers in category).
        let layerIndexInDestinationCategory = countLayersInCategory(
          updatedScenarios[index],
          destinationCategoryId
        );

        // Store the index of the original  category.
        let originalCategoryIndex = findCategoryIndexById(
          updatedScenarios[index],
          originalCategoryId
        );

        // Store the original layer config from the category to
        // add it later to the updated layer.
        let originalLayerConfig = clone(
          updatedScenarios[index].full_project.categories[originalCategoryIndex]
            .layers[layerIndexInOriginalCategory].config
        );

        // Remove the original layer from the original category.
        updatedScenarios[index].full_project.categories[
          originalCategoryIndex
        ].layers.splice(layerIndexInOriginalCategory, 1);

        // Update the result layer index prop with the new index in
        // the destination category.
        updatedResultLayer.index = layerIndexInDestinationCategory;

        // Store the index of the destination category.
        let destinationCategoryIndex = findCategoryIndexById(
          updatedScenarios[index],
          destinationCategoryId
        );

        // Add original layer config to the new layer
        updatedResultLayer.config = clone(originalLayerConfig);

        // Add the result layer at the end of the destination category.
        updatedScenarios[index].full_project.categories[
          destinationCategoryIndex
        ].layers.splice(layerIndexInDestinationCategory, 0, updatedResultLayer);
      } else {
        // Case without category change.
        let updatedResultLayer = clone(resultLayer);

        // Remove updated layer config property, as we need
        // the original one from the category.
        delete updatedResultLayer.config;

        // Store layer index in original category.
        let layerIndexInOriginalCategory = findLayerIndexById(
          updatedScenarios[index],
          originalLayer.id,
          originalCategoryId
        );

        // Store the index of the original  category.
        let originalCategoryIndex = findCategoryIndexById(
          updatedScenarios[index],
          originalCategoryId
        );

        // Store the original layer config from the category to
        // add it later to the updated layer.
        let originalLayerConfig = clone(
          updatedScenarios[index].full_project.categories[originalCategoryIndex]
            .layers[layerIndexInOriginalCategory].config
        );

        // Add original layer config to the new layer
        updatedResultLayer.config = clone(originalLayerConfig);

        // Update the layer in the original category.
        updatedScenarios[index].full_project.categories[
          originalCategoryIndex
        ].layers.splice(layerIndexInOriginalCategory, 1, updatedResultLayer);
      }
    }
    // Update scenarios context with the layer updated
    this.setState({
      scenarios: updatedScenarios
    });
  };

  /**
   * Internal call to update layer categories in the UI without call back end
   */
  updateLayerCategory = (
    sourceCategoryId,
    targetCategoryId,
    sourceLayerIndex,
    destinationLayerIndex
  ) => {
    try {
      // Store current scenarios array
      let updatedScenarios = [...this.state.scenarios];

      // Iterate scenarios to change layer category
      for (let index = 0; index < updatedScenarios.length; index++) {
        // Store source and destination category indexes
        let sourceCategoryIndex = findCategoryIndexById(
          updatedScenarios[index],
          sourceCategoryId
        );
        let destinationCategoryIndex = findCategoryIndexById(
          updatedScenarios[index],
          targetCategoryId
        );

        // Store the layer to move from source category to destination category
        let layer =
          updatedScenarios[index].full_project.categories[sourceCategoryIndex]
            .layers[sourceLayerIndex];

        // Update layer category with the target category id
        layer.category = Number(targetCategoryId);

        // Add the layer to the destination category
        updatedScenarios[index].full_project.categories[
          destinationCategoryIndex
        ].layers.splice(destinationLayerIndex, 0, layer);

        // Remove the layer from the source category
        updatedScenarios[index].full_project.categories[
          sourceCategoryIndex
        ].layers.splice(sourceLayerIndex, 1);
      }

      // Update scenarios context with the layer moved
      this.setState({
        scenarios: updatedScenarios
      });
    } catch (err) {
      console.log(err);
    }
  };

  updateAllLayersWithinCategory = (param, value, scenario_id, category_id) => {
    /**
     * Internal call to update all layers within a category values
     */

    let sceTemp = this.state.scenarios;
    let sceIndex = sceTemp.findIndex(item => item.id === scenario_id);

    //Early exit doing nothing but avoding problems
    if (sceIndex === -1) {
      return;
    }

    let catIndex = sceTemp[sceIndex].full_project.categories.findIndex(
      item => item.id === category_id
    );

    sceTemp[sceIndex].full_project.categories[catIndex].layers.map(layer => {
      if (layer.ltype === "RST") {
        return false;
      }
      layer.config[param] = value;
      return false;
    });

    try {
      this.setState({ scenarios: sceTemp });
    } catch (es) {
      console.log(es);
    }
  };

  /*
   * Internal call to update layer values in the UI without call backend.
   */
  updateCategory = (param, value, scenario_id, category_id) => {
    let sceTemp = [...this.state.scenarios];

    let sceIndex = sceTemp.findIndex(item => item.id === scenario_id);

    let catIndex = sceTemp[sceIndex].full_project.categories.findIndex(
      item => item.id === category_id
    );

    sceTemp[sceIndex].full_project.categories[catIndex][param] = value;

    this.setState({ scenarios: sceTemp });
  };

  updateActiveScenario = (name, value) => {
    /*
    API call to update scenario values .

    @ param name - scenario property name
    @ param scenario property new value

    Will update the local representation of the scenario

    */
    let temp = this.state.scenarios;
    temp[this.state.active_scenario][name] = value;
    this.setState({ scenarios: temp });
  };

  /*
   * Simply returns the current active scenario
   */
  getActiveScenario = () => {
    return this.state.scenarios[this.state.active_scenario];
  };

  updateScenario = (name, value) => {
    /*
    API call to update the active scenario values

    @ param name - scenario property name
    @ param scenario property new value

    */
    let temp = this.state.scenarios[this.state.active_scenario];
    temp[name] = value;
    return patchScenario(temp.id, temp)
      .then(res => {
        //only set the new state once we know the
        //call was successful. Not setting it
        //as the result since it does not incorporate
        //the full_project dataset
        let scenarios = this.state.scenarios.slice();
        scenarios[this.state.active_scenario] = Object.assign(
          scenarios[this.state.active_scenario],
          res
        );
        this.setState({ scenarios: scenarios });
        return true;
      })
      .catch(err => {
        this.props.enqueueSnackbar(parseCatchErr(err, this.props.t), {
          variant: "error"
        });
        return false;
      });
  };

  updateSpecificScenario = (id, name, value) => {
    /*
    API call to update values of the scenario with a specific id

    @ param id - scenario id
    @ param name - scenario property name
    @ param scenario property new value

    */
    const foundScenario = this.state.scenarios.find(
      scenario => scenario.id === id
    );
    if (!foundScenario) return;

    const updatedScenario = {
      ...foundScenario,
      [name]: value
    };
    patchScenario(id, updatedScenario)
      .then(() => {
        let updatedScenarios = this.state.scenarios.map(scenario =>
          scenario.id === id ? updatedScenario : scenario
        );
        if (name === "name") {
          // Alphabetical order
          getSortedData(updatedScenarios, "name");

          const active_scenario = updatedScenarios.findIndex(
            item => item.id === id
          );
          this.setState({
            scenarios: updatedScenarios,
            scenario: updatedScenarios[active_scenario],
            active_scenario: active_scenario
          });
        } else {
          this.setState({
            scenarios: updatedScenarios,
            scenario: updatedScenarios[this.state.active_scenario]
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.props.enqueueSnackbar(parseCatchErr(err, this.props.t), {
          variant: "error"
        });
      });
  };

  updateCategoryWeight = (name, value) => {
    /*
    updates the state value of the category weight and updates the total weight
    value to show it in screen .

    @ param  name - category object id
    @ param  value - new weight value ,
    */
    let temp = this.state.scenarios;
    temp[this.state.active_scenario].full_project.categories[
      name
    ].config.weight = value;
    // Alphabetical order
    getSortedData(temp, "name");

    // temp[name].config.weight = value;
    this.setState({
      scenarios: [...temp]
    });
  };
  refreshLeftMenu = () => {
    // Reload the same tab to refhesh the content
    let temp = this.state.left_tab;
    this.setState({ left_tab: 20 });
    this.setState({ left_tab: temp });
  };

  /**
   * update the area of the project
   * @param {*} area default none, polygon feature
   * @returns
   */
  updateArea = area => {
    let temp = Object.assign({}, this.state.project);
    temp.area = area;
    return patchProject(this.state.project.id, temp)
      .then(res => {
        this.setState({ project: res, scenarios_raw: res.scenarios });
        return true;
      })
      .catch(err => {
        this.props.enqueueSnackbar(err, { variant: "error" });
        this.setState({ project: temp, scenarios_raw: temp.scenarios });
        return false;
      });
  };

  /**
   * Load currents projects list
   */
  loadProjects = async () => {
    this.setState({ projectsLoaded: false });

    getProjects()
      .then(data => {
        if (data && typeof data !== "undefined") {
          this.setState({ projects: data });
        }
      })
      .catch(err =>
        this.props.enqueueSnackbar(parseCatchErr(err, this.props.t), {
          variant: "error"
        })
      )
      .finally(() => {
        this.setState({ projectsLoaded: true });
      });
  };

  loadProject = id => {
    /*
    Load certain project as active project with its default scenario

    @ param  id - project id
    @ return none
    */
    getProjects(id)
      .then(data => {
        if (data && typeof data.scenarios !== "undefined") {
          this.setState({ project: data, scenarios_raw: data.scenarios });
          this.updateScenarios();
          localStorage.setItem("lastProject", id);
        } else {
          console.log("No data available");
        }
      })
      .catch(err =>
        this.props.enqueueSnackbar(parseCatchErr(err, this.props.t), {
          variant: "error"
        })
      );
  };

  /**
   * Loads scenarios asynchronously.
   * @returns {Promise<void>} A promise that resolves when scenarios are loaded.
   */
  loadScenarios = async () => {
    // routing of updateScenarios, pendant to change,
    await this.updateScenarios();
  };

  /**
   * Method to re-fetch scenario from back-end and update the context
   * state. It has an optional parameter to specify the id, otherwise it
   * will update the active scenario.
   *
   * If the scenario has children, it creates am array of promises by
   * iterating the children ids, otherwise it uses only the original
   * scenario id.
   *
   * @param {number|null} scenarioId - The ID of the scenario to fetch. If null, the active scenario ID will be used.
   * @returns {Promise<number>} - The ID of the reloaded scenario.
   * @throws {Error} - If an error occurs while fetching the scenario.
   */
  reloadScenario = async (scenarioId = null) => {
    // Set the scenario id to fetch to the one in the parameter
    // or the active scenario id otherwise.
    const tmpScenarioId = scenarioId || this.state.scenario.id;

    // Find the scenario in the list of scenarios in the context.
    const tmpScenario = this.state.scenarios.find(
      scenario => scenario.id === tmpScenarioId
    );

    // Create the array of promises to fetch scenarios, including
    // the scenario passed in the parameters (active othetwise).
    let promises = [getScenarios(tmpScenarioId)];

    // If the scenario has children, iterate them to add them
    // into the to fetch promises array.
    if (tmpScenario.children.length > 0) {
      for (const childScenarioId of tmpScenario.children) {
        promises.push(getScenarios(childScenarioId));
      }
    }

    // Resolve the promises to fetch the scenario
    // and its childen if it has children.
    try {
      const updatedScenarios = await Promise.all(promises);

      // Store the current list of scenarios.
      let tmpScenarios = clone(this.state.scenarios);

      // Iterate the current list of scenarios, to locate the ones
      // updated and change them by the ones fetched.
      for (let index = 0; index < tmpScenarios.length; index++) {
        // Find the current iterated scenario in the updatedSceanios list.
        const matchedScn = updatedScenarios.find(
          item => item.id === tmpScenarios[index].id
        );
        // If it's a match, we change the current scenario by the updated one
        // in the tmp scenario list.
        if (matchedScn) {
          tmpScenarios[index] = matchedScn;
        }
      }

      // Once we have the temporary scenarios list updated with
      // the fetched scenarios, we proceed to update the states.
      await waitForStateToUpdate(this.setState.bind(this), {
        scenarios: tmpScenarios,
        scenario: tmpScenarios[this.state.active_scenario]
      });

      let categories = [];
      categories =
        tmpScenarios[this.state.active_scenario].full_project.categories;

      let total_weight = 0;
      categories.map(item => {
        return (total_weight += item.config.weight);
      });

      await waitForStateToUpdate(this.setState.bind(this), {
        category_total_weight: total_weight
      });

      return scenarioId;
    } catch (err) {
      this.props.enqueueSnackbar(parseCatchErr(err, this.props.t), {
        variant: "error"
      });
      throw err;
    }
  };

  /**
   * Changes the actual project.
   *
   * @param {number} id - The project id.
   * @param {number|null} scenarioId - The scenario id (default null).
   * @param {string|null} userType - The user role (default null).
   * @returns {Promise<boolean|object>} - Returns true if the user role is viewer, otherwise returns the project data.
   */
  changeProject = async (id, scenarioId = null, userType = null) => {
    await waitForStateToUpdate(this.setState.bind(this), {
      scenariosLoaded: false
    });

    try {
      const data = await getProjects(id);
      await waitForStateToUpdate(this.setState.bind(this), {
        scenarios_raw: []
      });

      let index = 0;
      // Check if we get a stored scenario and find its index
      if (scenarioId) {
        const tempScenariosSorted = data.scenarios;
        getSortedData(tempScenariosSorted, "name");
        index = data.scenarios.findIndex(item => item.id === scenarioId);
        if (index === -1) index = 0;
      }

      if (data && typeof data.scenarios !== "undefined") {
        await waitForStateToUpdate(this.setState.bind(this), {
          project: data,
          active_scenario: index,
          scenarios_raw: data.scenarios,
          scenarios: [],
          scenario: {}
        });

        localStorage.setItem("lastProject", id);
        await this.updateScenarios(null, index);
        if (data.scenarios[index] === undefined && userType === T_VIEWER) {
          return true;
        } else {
          this.changeScenario(data.scenarios[index].id, index);
          return data;
        }
      }
    } catch (err) {
      this.props.enqueueSnackbar(parseCatchErr(err, this.props.t), {
        variant: "error"
      });
    }
  };

  /**
   * Reloads the project.
   *
   * @param {number} [project_id=this.state.project.id] - The project ID. Defaults to the current project ID.
   * @param {number} [scenario_id=this.state.scenario.id] - The scenario ID. Defaults to the current scenario ID.
   * @returns {Promise<void>} - A promise that resolves when the project is reloaded.
   */
  reloadProject = async (
    project_id = this.state.project.id,
    scenario_id = this.state.scenario.id
  ) => {
    try {
      const data = await getProjects(project_id);

      if (data && typeof data.scenarios !== "undefined") {
        // This is a way to update the state and wait for it to finish
        // We need to do this in order to be able to await reloadProject
        // because if not, the function will be awaited until the setState,
        // anything after that would be executed after the await completes (in the old code)
        await waitForStateToUpdate(this.setState.bind(this), {
          project: data,
          scenarios_raw: data.scenarios
        });
        //
        await this.updateScenarios();
        this.changeScenario(scenario_id);
        localStorage.setItem("lastProject", project_id);
      } else {
        this.props.enqueueSnackbar(this.props.t("error.Nodataavailable"), {
          variant: "error"
        });
      }
    } catch (err) {
      this.props.enqueueSnackbar(parseCatchErr(err, this.props.t), {
        variant: "error"
      });
    }
  };

  /**
   * Deletes a scenario and updates the state accordingly.
   *
   * @param {number} scenario_id - The ID of the scenario to be deleted.
   */
  deleteScenario = scenario_id => {
    const activeScenario = this.state.scenarios[this.state.active_scenario];

    const activeScenarioId = activeScenario.id;

    const scenarioChildren = activeScenario.children || [];

    // Filter out the scenario to be deleted and its children.
    let scenarios = this.state.scenarios
      .filter(item => item.id !== scenario_id)
      .filter(item => !scenarioChildren.includes(item.id));

    let scenarios_raw = this.state.scenarios_raw
      .filter(item => item.id !== scenario_id)
      .filter(item => !scenarioChildren.includes(item.id));

    if (scenario_id === activeScenarioId) {
      this.setState({
        scenarios: scenarios,
        scenarios_raw: scenarios_raw,
        active_scenario: 0
      });
      this.changeScenario(0);
    } else {
      const activeScenarioIndex = scenarios.findIndex(
        item => item.id === activeScenarioId
      );
      this.setState({
        scenarios: scenarios,
        scenarios_raw: scenarios_raw,
        active_scenario: activeScenarioIndex
      });
    }
  };

  // Function that removes the layer in the local object scenario
  // It iterates over all the scenarios due the same layer is in every scenario
  deleteLayer = layer_id => {
    let temp = this.state.scenarios.slice();
    temp = temp.map(scenario => {
      let categories_clean = scenario.full_project.categories.slice();
      categories_clean = categories_clean.map(category => {
        let filtered_layers = category.layers.filter(
          item => item.id !== layer_id
        );
        category["layers"] = filtered_layers;
        return category;
      });
      scenario.categories = categories_clean;
      return scenario;
    });
    this.setState({ scenarios: temp });
  };

  listProjects = () => {
    /*
    Main loading function, checks if there is data about the last project and
    scenario used by the user, if not, loads the first project in the list

    @ param  no params
    @ return API return object
    */
    if (this.state.projects.length === 0) {
      getProjects().then(data => {
        try {
          if (data && typeof data[0] !== "undefined") {
            this.setState({ projects: data });
            try {
              if (localStorage.getItem("lastProject")) {
                // Check for the last project opened by the user
                this.loadProject(localStorage.getItem("lastProject"));
                if (
                  // check for the last scenario opened by the user
                  typeof this.state.projects !== "undefined"
                ) {
                  let lastActiveScenario = this.state.project.scenarios[0];
                  this.changeScenario(lastActiveScenario);
                  this.props.enqueueSnackbar(
                    this.props.t("common.LoadingScenario"),
                    {
                      variant: "success"
                    }
                  );
                }
              } else {
                // Opens the default project
                this.changeProject(data[0].id);
              }
            } catch (e) {
              this.changeProject(data[0].id);
            }
          }
        } catch {
          this.changeProject(data[0].id);
        }
      });
    }
  };

  /**
   * Method to retrieve fresh project and scenarios data from back-end
   * to update global state.
   *
   * We call getProjects (back-end) for the current project. Then
   * from the project object we save 'scenarios' property as 'scenariosRaw'
   * and we iterate it to call getScenarios (back-end) for each scenario.
   * Then we save that info in an array 'scenarios'.
   *
   * Finally we update the state properties 'project', 'scenarios_raw', and
   * 'scenarios'.
   *
   * @param { number } projectId
   *
   */
  refreshProject = async (projectId = null) => {
    try {
      // Retrieve current project from back-end.
      const project = await getProjects(
        projectId ? projectId : this.state.project.id
      );

      let scenarios = [];

      if (project && typeof project.scenarios !== "undefined") {
        // Iterate scenarios to call back-end for each one.
        let index;
        let scenariosLength = project.scenarios.length;
        for (index = 0; index < scenariosLength; index++) {
          const scenarioRaw = project.scenarios[index];

          // Call back-end to retrieve scenario data.
          const scenario = await getScenarios(scenarioRaw.id);

          // Store scenario data in scenarios array.
          scenarios.push(scenario);
        }

        // Update state with project, scenariosRaw and scenarios.
        this.setState({
          project: project,
          scenarios_raw: project.scenarios,
          scenarios: getSortedData(scenarios, "name")
        });
      } else {
        // Error when no project data retrieved or when no scenarios
        // defined in the project.
        this.props.enqueueSnackbar(this.props.t("error.Nodataavailable"), {
          variant: "error"
        });
      }
    } catch (error) {
      // Error fetching data from back-end
      this.props.enqueueSnackbar(parseCatchErr(error, this.props.t), {
        variant: "error"
      });
    }
  };

  /**
   * Method to retrieve fresh data from back-end for a given array of
   * scenarios.
   *
   * The scenarios can already exist or be a new one, so we search for
   * the scenario id in the current scenarios list in context.
   * If the scenario exists, we replace it with the data retrieved from
   * back-end. Otherwise we add the new scenario to the scenarios list in
   * context.
   *
   * @param { array } scenarioIds
   */
  refreshScenarios = async (
    scenarioIds = null,
    options = { isShared: false }
  ) => {
    let currentScenarios = clone(this.state.scenarios);
    let currentScenariosRaw = clone(this.state.scenarios_raw);
    try {
      // We iterate the arry of scenario ids.
      let scenariosLength = scenarioIds?.length;
      for (let index = 0; index < scenariosLength; index++) {
        // Save the current iteration scenario id.
        const scenarioId = options.isShared
          ? scenarioIds[index].id
          : scenarioIds[index];

        // First we retireve the scenario from back-end
        let freshScenario;
        try {
          freshScenario = await getScenarios(scenarioId);

          // If there's an error retrieving the scenario
          // and it's a shared scenario, we fire an error.
          if (!freshScenario && options.isShared) {
            this.props.enqueueSnackbar(
              this.props.t("AddScenario.ErrorShared"),
              {
                variant: "error"
              }
            );
            return;
          }
        } catch (err) {
          this.props.enqueueSnackbar(this.props.t("AddScenario.ErrorShared"), {
            variant: "error"
          });
        }

        // If this is a shared scenario we add a
        // property to flag it.
        if (options.isShared) {
          Object.assign(freshScenario, {
            isShared: true,
            owner: scenarioIds[index].owner
          });
        }

        // Then we check if the scenario already exists or
        // it's a new one.
        const existingScenario = searchObjectInArray(
          scenarioId,
          "id",
          currentScenarios
        );

        if (existingScenario) {
          // If the scenario already existed, we replace it with
          // the retrieved from back-end.

          // We find the index of the scenario on the scenarios
          // list in the state.
          const existingScenarioIndex =
            currentScenarios.indexOf(existingScenario);

          // We replace the existing scenario with the fresh one.
          currentScenarios[existingScenarioIndex] = freshScenario;
        } else {
          // If the scenario doesn't exists, we add it to
          // the scenarios list in the state.
          currentScenarios.push(freshScenario);

          // Then we retireve basic scneario data from back-end
          // to update scenarios_raw.
          const freshScenarioRaw = await getScenariosRaw(freshScenario.id);

          // And we build the scnerarios_raw object with building_count, id,
          // intermediate_points and name properties.
          const newScenarioRaw = (({
            building_count,
            id,
            intermediate_points,
            name
          }) => ({
            building_count,
            id,
            intermediate_points,
            name
          }))(freshScenarioRaw);

          currentScenariosRaw.push(newScenarioRaw);
        }
      }

      // Finally we update the state with the new list of scenarios.
      this.setState({
        scenarios: getSortedData(currentScenarios, "name"),
        scenarios_raw: getSortedData(currentScenariosRaw, "name"),
        scenario: currentScenarios[this.state.active_scenario],
        scenariosLoaded: true
      });
    } catch (error) {
      // Error fetching data from back-end
      this.props.enqueueSnackbar(
        typeof error === "object" ? error.message : error,
        {
          variant: "error"
        }
      );
    }
  };

  addSharedScenarios = sharedScenarios => {
    // Store current scenarios to add the new one
    let currentScenarios = this.state.scenarios.slice() || [];

    let promises;

    // If we don't have parameters, we update all
    // the scenarios
    promises = [
      ...sharedScenarios.map(item =>
        getScenarios(item).then(data => {
          if (data !== undefined) {
            currentScenarios.push(data);
          }
        })
      )
    ];

    return Promise.all(promises)
      .then(() => {
        getSortedData(currentScenarios, "name");

        this.setState({
          scenarios: currentScenarios,
          scenario: currentScenarios[this.state.active_scenario]
        });
        //}
        let categories = [];
        if (currentScenarios.length > 0) {
          categories =
            currentScenarios[this.state.active_scenario].full_project
              .categories;
        }

        let total_weight = 0;
        categories.map(item => {
          return (total_weight += item.config.weight);
        });
        this.setState({ category_total_weight: total_weight });
        this.props.enqueueSnackbar("Shared scenarios added sucessfully.", {
          variant: "success"
        });
      })
      .catch(err => {
        this.props.enqueueSnackbar(err, { variant: "error" });
      });
  };

  updateScenarios = async (scenarioId = null, scenarioIndex = null) => {
    /*
    Function that refresh the scenario values
    */

    // Store current scenarios to add the new one
    let currentScenarios =
      this.state.scenarios
        .slice()
        .filter(scenario => scenario.id !== scenarioId) || [];

    let promises;
    if (scenarioId !== null) {
      // If we have a scenario id as a parameter, we call
      // getScenario just for that id
      promises = [
        getScenarios(scenarioId).then(data => {
          if (data !== undefined) {
            currentScenarios.push(data);
          }
        })
      ];
    } else {
      currentScenarios = [];
      // If we don't have parameters, we update all
      // the scenarios
      promises = [
        ...this.state.scenarios_raw.map(item =>
          getScenarios(item.id).then(data => {
            if (data !== undefined) {
              currentScenarios.push(data);
            }
          })
        )
      ];
    }

    const promisesResult = await Promise.all(promises);
    try {
      // We update current scenarios in context with the newly added one.
      // if (scenarioId !== null) {
      //   // Alphabetical order
      //   getSortedData(currentScenarios, "name");

      //   this.setState({
      //     scenarios: currentScenarios,
      //     scenario: currentScenarios[this.state.active_scenario]
      //   });
      // } else {
      // Alphabetical order
      getSortedData(currentScenarios, "name");

      this.setState({
        scenarios: currentScenarios,
        scenario:
          currentScenarios[
            scenarioIndex ? scenarioIndex : this.state.active_scenario
          ]
      });

      //}
      let categories = [];
      if (currentScenarios.length > 0) {
        categories =
          currentScenarios[
            scenarioIndex ? scenarioIndex : this.state.active_scenario
          ].full_project.categories;
      }

      let total_weight = 0;
      categories.map(item => {
        return (total_weight += item.config.weight);
      });
      this.setState({ category_total_weight: total_weight });

      /**
       * ADDING SHARED SCENARIOS
       */

      // Recover all the shared scenarios from local storage.
      const sharedScenarios = JSON.parse(
        localStorage.getItem("sharedScenarios")
      );

      // If there are shared scenarios and specifically an
      // entry for the current project, we add them.
      if (sharedScenarios && sharedScenarios[this.state.project.id]) {
        this.refreshScenarios(sharedScenarios[this.state.project.id], {
          isShared: true
        });
      } else {
        this.setState({
          scenariosLoaded: true
        });
      }
    } catch (err) {
      if (typeof err === "object") {
        this.props.enqueueSnackbar(err.message, { variant: "error" });
      } else {
        this.props.enqueueSnackbar(err, { variant: "error" });
      }
    }
  };

  /**
   * Change model id for a specific scenario id
   */
  updateScenariosModelLight = (scenario_id, model_id) => {
    let scenario_index = this.state.scenarios.findIndex(
      item => item.id === scenario_id
    );

    let sceTemp = this.state.scenarios;
    // UPdate scenario pylon value
    sceTemp[scenario_index]["pylon"] = model_id;
    // Update scenario Config pylon value
    sceTemp[scenario_index].scenarioconfig.pylon = model_id;

    this.setState({ scenarios: sceTemp });
  };

  /**
   * Changes the actual scenario to certain id scenario
   * @param {*} id default actual_scenario_id - scenario id
   * @param {*} scenarioIndex scenario index
   */
  changeScenario = (id, scenarioIndex = null) => {
    // Prevent passing the id as a string
    // Check if we received an scenario index and if not find it
    let index = scenarioIndex
      ? scenarioIndex
      : this.state.scenarios.findIndex(item => item.id === Number(id));
    if (index === -1) index = 0;

    let temp = this.state.scenarios[index];
    this.setState({
      active_scenario: index,
      scenario: temp
    });
    try {
      let categories = temp.full_project.categories;
      let total_weight = 0;
      categories.map(item => {
        return (total_weight += item.config.weight);
      });
      this.setState({
        category_total_weight: total_weight,
        categories: categories // set var with categories for 3d view
      });
    } catch {}
  };

  categorySettingChanged = value => {
    // Change the category weighting option
    if (value === SET_WEIGHT_ABS) {
      this.setState({ category_setting: SET_WEIGHT_ABS });
      this.constrainCategoryWeights(1, 10);
    } else if (value === SET_WEIGHT_REL) {
      this.setState({ category_setting: SET_WEIGHT_REL });
      this.constrainCategoryWeights(1, 100);
    } else if (value === SET_WEIGHT_NOWEIGHT) {
      this.setState({ category_setting: SET_WEIGHT_NOWEIGHT });
      this.constrainCategoryWeights(1, 1);
    }
  };
  constrainCategoryWeights = (min, max) => {
    /*
    Function that sets all category weights to an expected maximum
    and minimum (trimming higher and lower values)
    */
    this.state.scenarios[
      this.state.active_scenario
    ].full_project.categories.map((item, index) => {
      let new_weight = Math.max(min, Math.min(item.config.weight, max));
      return patchCategoryConfig(item.config.id, {
        weight: new_weight,
        scenario_id: item.config.scenario
      })
        .then(res =>
          this.updateCategory("config", res, item.config.scenario, item.id)
        )
        .catch(err =>
          this.props.enqueueSnackbar(parseCatchErr(err, this.props.t), {
            variant: "error"
          })
        );
    });
  };

  /*
   *  Function that sets a "dirty" flag for a layer, if the layer is
   *  dirty means that the feature that contains is outdated and should
   *  retrieve a new one.
   */
  setLayerDirty = (
    status = false,
    category_id,
    layer_id,
    originalLayer = null
  ) => {
    try {
      // Store a copy of the current active scenario data to work with.
      let scenariosCopy = clone(this.state.scenarios);
      let activeScenarioIndex = this.state.active_scenario;

      let category_index = null;
      let layer_index = null;

      if (originalLayer === null) {
        // If there's no originalLayer, whe search for the
        // layer with the current layer category.
        category_index = findCategoryIndexById(
          scenariosCopy[activeScenarioIndex],
          category_id
        );
        layer_index = findLayerIndexById(
          scenariosCopy[activeScenarioIndex],
          layer_id,
          category_id
        );
      } else {
        // If there's an originalLayer, we search for the
        // layer with the original layer category.
        category_index = findCategoryIndexById(
          scenariosCopy[activeScenarioIndex],
          originalLayer.category
        );

        layer_index = findLayerIndexById(
          scenariosCopy[activeScenarioIndex],
          layer_id,
          originalLayer.category
        );
      }

      // Set the layer as dirty in the active scenario.
      scenariosCopy[activeScenarioIndex]["full_project"]["categories"][
        category_index
      ]["layers"][layer_index]["dirty"] = status;

      // Set the layer processing status.
      if (status === false) {
        scenariosCopy[activeScenarioIndex]["full_project"]["categories"][
          category_index
        ]["layers"][layer_index]["processing"] = status;
      }

      // Update scenarios in global context.
      this.setState({ scenarios: scenariosCopy });
    } catch (e) {
      console.log(e);
    }
  };

  /*
   * Function that sets a "processing" flag for a layer, if the
   * layer is processing means that the feature that contains is
   * outdated and should retrieve a new one
   */
  setLayerProcessing = (status, categoryId, layerId, originalLayer = null) => {
    try {
      // Store a copy of the current active scenario data to work with.
      let scenariosCopy = this.state.scenarios;
      let activeScenarioIndex = this.state.active_scenario;

      // Find the layer to set it to processing state.
      const categoryIndex = scenariosCopy[activeScenarioIndex]["full_project"][
        "categories"
      ].findIndex(item => item.id === originalLayer.category);

      const layerIndex = scenariosCopy[activeScenarioIndex][
        "full_project"
      ].categories[categoryIndex]["layers"].findIndex(
        item => item.id === layerId
      );

      // Set the layer as processing.
      scenariosCopy[activeScenarioIndex]["full_project"]["categories"][
        categoryIndex
      ]["layers"][layerIndex]["processing"] = status;

      // Update scenarios in global state.
      this.setState({ scenarios: scenariosCopy });
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Update corridor width setting.
   * @return null
   */
  setCorridorWidth = width => {
    this.setState({ corridorWidth: width });
  };

  /**
   * Read corridor width setting
   * @return corridor width value
   */
  getCorridorWidth = () => {
    if (this.state.corridorWidth !== undefined) return this.state.corridorWidth;
    return 5; // default
  };

  /**
   * Update userSettings.
   * This function is used in 2D and 3D
   * @return null
   */
  setUserSettings = userSettings => {
    this.setState({ userSettings: userSettings });
  };

  /**
   * Read cesium parameters (Token ...)
   */
  getUserSettings = () => {
    axios.get("cesiumSetting.json").then(res => {
      // Set CesiumIon Token when app start
      Ion.defaultAccessToken = res.data.token;
      // Set settings to appProvider
      this.setUserSettings(res.data);
    });
  };

  /**
   * Filter the state retrieved backgrounds returning only
   * the Ones that should be rendered in 2d.
   *
   * This mostly filters out types not supported in a 2d
   * mode (like 3dTiles) and others that might not be supported
   * by the OpenLayers library
   *
   * @returns An array of BaseMap definitions
   */
  getBackgrounds2D = () => {
    const invalid_types = [];
    return this.state.backgrounds.filter(bMap => {
      return !bMap.layers.some(bMapLayer =>
        invalid_types.includes(bMapLayer.type)
      );
    });
  };

  /**
   * Filter the state retrieved backgrounds returning only
   * the Ones that should be rendered in 3d.
   *
   * This mostly filters out types not supported in a 2d
   * mode (like MapBox) and others that might not be supported
   * by the Cesium library
   *
   * @returns An array of BaseMap definitions
   */
  getBackgrounds3D = () => {
    const invalid_types = ["MAPBOX"];
    return this.state.backgrounds.filter(bMap => {
      return !bMap.layers.some(bMapLayer =>
        invalid_types.includes(bMapLayer.type)
      );
    });
  };

  /**
   * Retrieve backgrounds from the backend and store those
   * in the state.
   */
  fetchBackgrounds = () => {
    //otherwise, return the promise to download the data
    return getBaseMaps().then(res => {
      for (let index = 0; index < res.length; index++) {
        //fill thumbnails. Why don't we just use the thumbnail
        //value instead of filling an "image" one?
        if (res[index].thumbnail) {
          res[index]["image"] = res[index].thumbnail;
        } else {
          res[index]["image"] = "/layer.png";
        }
      }

      this.setState({ backgrounds: res });
    });
  };

  showUserProfile = tab => {
    this.setState({ userProfile: true, userProfileActiveTab: tab });
  };
  hideUserProfile = () => {
    this.setState({ userProfile: false });
  };
  addProcess = ({
    task_id = "0",
    details = "no details",
    state = "PENDING",
    successCallback = function () {},
    failureCallback = function () {},
    killedCallback = function () {}
  }) => {
    this.setState({
      tasks: {
        ...this.state.tasks,
        [task_id]: {
          task_id: task_id,
          details: details,
          state: state,
          successCallback: successCallback,
          failureCallback: failureCallback,
          killedCallback: killedCallback
        }
      },
      tasksLogOpen: true
    });
  };

  setTasks = tasks => {
    this.setState({ tasks });
  };

  setProcessLog = state => {
    this.setState({
      tasksLogOpen: state !== undefined ? state : !this.state.tasksLogOpen
    });
  };

  /*
  Function that removes a user uploaded path from the local scenarios
  object (and from the local scenario object if the path belongs to the active scenario)
   */
  deletePath = (scenarioId, pathId) => {
    const updatedScenarios = this.state.scenarios.map(scenario => {
      if (scenario.id === scenarioId) {
        return {
          ...scenario,
          paths: scenario.paths.filter(path => path.id !== pathId)
        };
      } else {
        return scenario;
      }
    });

    this.setState({
      scenarios: updatedScenarios,
      scenario: updatedScenarios[this.state.active_scenario]
    });
  };

  /**
   * Add new layer: Function that adds a new layer to the scenarios object
   */
  addNewLayer = layerId => {
    // Get the current scenarios array
    let scenariosSnapshot = this.state.scenarios.slice();

    // Prepare array for the updated scenarios
    let scenariosUpdated = [];
    let promises = [];

    // Iterate all the scenarios
    for (let index = 0; index < scenariosSnapshot.length; index++) {
      // Create an array of promises to update layers in category for each scenario
      promises.push(
        getFullProjectLayer(scenariosSnapshot[index].id, layerId)
          .then(layer => {
            const categoryId = layer.category;
            let scenarioCategories =
              scenariosSnapshot[index]["full_project"]["categories"];

            // Iterate the categories
            for (let i = 0; i < scenarioCategories.length; i++) {
              if (scenarioCategories[i].id === categoryId) {
                scenarioCategories[i]["layers"].push(clone(layer));
              }
            }

            return scenariosSnapshot[index];
          })
          .then(scen => {
            scenariosUpdated.push(scen);
          })
      );
    }

    return Promise.all(promises).then(val => {
      // Alphabetical order

      getSortedData(scenariosUpdated, "name");

      this.setState({
        scenarios: scenariosUpdated
      });
    });
  };

  updateProjects = projects => {
    this.setState({ projects: projects });
  };

  showAllCategoryLayers = (scenario_id, category_id, visibility) => {
    let tempScenarios = this.state.scenarios;
    tempScenarios.map(scenario => {
      if (scenario.id === scenario_id) {
        scenario.full_project.categories = scenario.full_project.categories.map(
          category => {
            if (category.id === category_id) {
              category.layers = category.layers.map(layer => {
                if (!layer.has_errors && !(layer.ltype === "RST")) {
                  layer.config.ui_settings.visible = visibility;
                  return layer;
                } else {
                  return layer;
                }
              });
              return category;
            } else {
              return category;
            }
          }
        );
        return scenario;
      } else {
        return scenario;
      }
    });
    this.setState({ scenarios: tempScenarios });
  };

  showAllCategoryOriginalLayers = (scenario_id, category_id, visibility) => {
    let tempScenarios = this.state.scenarios;
    tempScenarios.map(scenario => {
      if (scenario.id === scenario_id) {
        scenario.full_project.categories = scenario.full_project.categories.map(
          category => {
            if (category.id === category_id) {
              category.layers = category.layers.map(layer => {
                if (!layer.has_errors && !(layer.ltype === "RST")) {
                  layer.config.ui_settings.baseVisible = visibility;
                  return layer;
                } else {
                  return layer;
                }
              });
              return category;
            } else {
              return category;
            }
          }
        );
        return scenario;
      } else {
        return scenario;
      }
    });
    this.setState({ scenarios: tempScenarios });
  };

  // removeProject:  remove project from the list, if it is the active project
  // it will open the welcome dialog
  removeProject = (projectId, callback) => {
    const { project, projects } = this.state;
    let filteredProjects = projects.filter(projectItem => {
      if (projectItem.id === projectId) {
        return false;
      } else {
        return true;
      }
    });
    if (project.id === projectId) {
      this.setState({ projects: filteredProjects, project: [] });
      callback();
    } else {
      this.setState({ projects: filteredProjects });
    }
  };

  /**
   * Sets the map 2D ready flag.
   *
   * @param { boolean } state // When true the OL map is ready.
   */

  setMap2DReady = state => {
    this.setState({ map2DReady: state });
  };

  /**
   * Sets the map 3D ready flag.
   *
   * @param { boolean } state // When true the Cesium map is ready.
   */

  setMap3DReady = state => {
    this.setState({ map3DReady: state });
  };

  /**
   * Sets the isScenarioPointsEditorOpen status flag.
   * @param { boolean } state
   */
  updateScenarioPointsEditorStatus = state => {
    this.setState({ isScenarioPointsEditorOpen: state });
  };

  updateProjectLoadingID = newProjectLoadingIDValue => {
    this.setState({
      projectLoadingID: newProjectLoadingIDValue
    });
  };

  updateProjectLoadState = newProjectLoadState => {
    this.setState({
      projectLoadState: newProjectLoadState
    });
  };

  render() {
    /*
    In a context object, the provider returns the accesible variables and
    methods, to make some function or variable visible by the AppConsumer
    append it to "value"  .

    */
    return (
      <div className="h-100 w-100">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.open}
          autoHideDuration={4000}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.message}</span>}
        />
        <AppContext.Provider
          value={{
            state: this.state,
            project: this.state.project,
            projectsLoaded: this.state.projectsLoaded, // flag to check if projects are loaded
            projects: this.state.projects, //list of loaded  projects
            scenarios: this.state.scenarios, //list of loaded  scenarios
            scenariosLoaded: this.state.scenariosLoaded,
            // category: this.state.category,
            // categories: this.state.categories, //list of loaded  categories
            // @DEPRECATED scenario. should use scenarios[active_scenario]
            scenario: this.state.scenarios[this.state.active_scenario],
            changeProject: this.changeProject,
            changeScenario: this.changeScenario,
            setEdit: this.setEdit,
            reloadProject: this.reloadProject,
            listProjects: this.listProjects,
            updateArea: this.updateArea,
            updateProject: this.updateProject,
            updateScenario: this.updateScenario,
            updateSpecificScenario: this.updateSpecificScenario,
            wizardArea: this.wizardArea,
            setWizard: this.setWizard,
            changeRightTab: this.changeRightTab,
            reloadScenario: this.reloadScenario,
            updateCategoryWeight: this.updateCategoryWeight,
            updateCategory: this.updateCategory,
            updateAllLayersWithinCategory: this.updateAllLayersWithinCategory,
            handleTab: this.handleTab,
            setMenuOpen: this.setMenuOpen,
            addProcess: this.addProcess,
            setTasks: this.setTasks,
            setProcessLog: this.setProcessLog,
            categorySettingChanged: this.categorySettingChanged,
            updateLayer: this.updateLayer,
            updateLayerAtt: this.updateLayerAtt,
            updateLayerConfig: this.updateLayerConfig,
            setProjects: this.setProjects,
            setProject: this.setProject,
            refreshProject: this.refreshProject,
            refreshScenarios: this.refreshScenarios,
            updateScenariosModelLight: this.updateScenariosModelLight,
            updateScenarios: this.updateScenarios,
            addSharedScenarios: this.addSharedScenarios,
            setLayerDirty: this.setLayerDirty,
            setLayerProcessing: this.setLayerProcessing,
            setRMDirty: this.setRMDirty,
            //Functions from Dashboard3D
            userSettings: this.state.userSettings, // get user settings   ,
            setUserSettings: this.setUserSettings,
            getCorridorWidth: this.getCorridorWidth,
            setCorridorWidth: this.setCorridorWidth,
            backgrounds: this.state.backgrounds, // get list of  Backgrounds  ,
            forms: {}, //blank object will have all the forms component functions like showWait , etc ...
            messages: {}, //blank object will have all the messages component functions like show error , show wait , etc ...
            //measure and draw tools
            drawObject: {}, // used for drawing only with OL ,
            data: {}, //include all projects data
            updateActiveScenario: this.updateActiveScenario,
            getActiveScenario: this.getActiveScenario,
            deleteScenario: this.deleteScenario,
            scenarioAnalytics: this.scenarioAnalytics,
            fetchBackgrounds: this.fetchBackgrounds,
            getBackgrounds2D: this.getBackgrounds2D,
            getBackgrounds3D: this.getBackgrounds3D,
            getUserSettings: this.getUserSettings,
            showUserProfile: this.showUserProfile,
            hideUserProfile: this.hideUserProfile,
            isContextLimits: this.isContextLimits,
            moveCesiumControls: this.moveCesiumControls,
            updateLayerCategory: this.updateLayerCategory,
            bufferLayerUpdate: this.bufferLayerUpdate,
            deleteLayer: this.deleteLayer,
            deletePath: this.deletePath,
            addNewLayer: this.addNewLayer,
            updateProjects: this.updateProjects,
            geoprocess: this.state.geoprocess,
            active_scenario: this.state.active_scenario,
            loadProject: this.loadProject,
            scenarios_raw: this.state.scenarios_raw,
            appendNewCategory: this.appendNewCategory,
            showAllCategoryLayers: this.showAllCategoryLayers,
            showAllCategoryOriginalLayers: this.showAllCategoryOriginalLayers,
            updateLayerVisibility: this.updateLayerVisibility,
            updateLayerBaseVisibility: this.updateLayerBaseVisibility,
            removeProject: this.removeProject,
            getUserCapabilities: this.getUserCapabilities,
            toggleFullscreen: this.toggleFullscreen,
            getModel: this.getModel,
            setSelectedModel: this.selectedModel,
            setMap2DReady: this.setMap2DReady,
            setMap3DReady: this.setMap3DReady,
            isScenarioPointsEditorOpen: this.isScenarioPointsEditorOpen,
            updateScenarioPointsEditorStatus:
              this.updateScenarioPointsEditorStatus,
            projectLoadState: this.state.projectLoadState,
            projectLoadingID: this.state.projectLoadingID,
            updateProjectLoadingID: this.updateProjectLoadingID,
            updateProjectLoadState: this.updateProjectLoadState
          }}
        >
          {this.props.children}
        </AppContext.Provider>
      </div>
    );
  }
}
let AppConsumer = AppContext.Consumer;
export { AppConsumer };
export default withTranslation()(withSnackbar(AppProvider));
